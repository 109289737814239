<template>
  <div class="liuyan">
    <Header ref="pagedetail"></Header>
    <main class="layout hide-aside content-inner">
      <div id="page">
        <div id="article-container">
          <p>
            <font
              ><strong style="font-size: x-large"
                >😀 欢迎你的来访，听说留言的人会有好运哦！</strong
              ></font
            >
          </p>
          <p>
            <strong
              ><font style="font-size: xx-large"
                >💬 有什么想对博主说的吗？</font
              ></strong
            >
          </p>
          <p>
            <strong
              ><font style="font-size: -webkit-xxx-large"
                >✔ 请在这里留下宝贵的意见。</font
              ></strong
            >
          </p>
        </div>
        <hr />
        <div id="post-comment">
          <div class="comment-head">
            <div class="comment-headline">
              <i class="fas fa-comments fa-fw"></i><span> 留言</span>
            </div>
          </div>
          <div class="comment-wrap">
            <el-form ref="form" :model="form">
              <el-form-item label="昵称/QQ号(必填)">
                <el-input
                  v-model="form.name"
                  minlength="2"
                  maxlength="10"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="邮箱(必填~保密)">
                <el-input v-model="form.mail" minlength="5" maxlength="20">
                </el-input>
              </el-form-item>
              <el-form-item label="个人站点">
                <el-input v-model="form.website"></el-input>
              </el-form-item>
              <el-form-item label="评论/留言(必填)">
                <el-input
                  type="textarea"
                  v-model="form.desc"
                  minlength="3"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitliuyan(this)"
                  >立即提交</el-button
                >
                <el-button>取消</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div id="post-comment">
          <div class="comment-head">
            <div class="comment-headline">
              <i class="fas fa-comments fa-fw"></i
              ><span> {{ commentdata.length }}评论</span>
            </div>
          </div>
          <div class="comment-wrap" v-loading="loading">
            <div class="vcards">
              <div
                class="vcard"
                v-for="(item, index) in commentdata"
                :key="index"
              >
                <img
                  class="vimg"
                  src="https://blog.likepoems.com/images/tou3.png"
                />
                <div class="vh">
                  <div class="vhead">
                    <span class="vnick">{{ item.visitor }}</span>
                    <span class="vvisitor">访客</span>
                    <span class="vsys">
                      <i class="el-icon-platform-eleme"></i>
                      {{ item.website }}
                    </span>
                  </div>
                  <div class="vtime">{{ item.time }}</div>
                  <div class="vcontent">
                    <p>{{ item.content }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <Footer></Footer>
    <ToTop></ToTop>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import ToTop from "@/components/ToTop";
export default {
  name: "Liuyan",
  data() {
    return {
      form: {
        name: "",
        mail: "",
        website: "",
        desc: "",
      },
      commentdata: [],
      loading: false,
    };
  },
  components: {
    Header,
    Footer,
    ToTop,
  },
  methods: {
    submitliuyan() {
      let reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
      if (!this.form.name) {
        alert("不是有效的昵称！");
        return;
      }
      if (!reg.test(this.form.mail)) {
        alert("不是有效的邮箱！");
        return;
      }
      if (!this.form.desc) {
        alert("不是有效的留言！");
        return;
      }
      this.$confirm("确认提交表单?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "提交成功!",
          });
          let liuyandata = {
            visitor: this.form.name,
            mail: this.form.mail,
            content: this.form.desc,
            website: this.form.website,
          };
          this.$axios
            .post("/api/writeliuyan", liuyandata)
            .then((response) => {
              console.log("success", response);
            })
            .catch((error) => {
              console.log("error", error);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消提交",
          });
        });
    },
    getliuyan() {
      this.loading = true;
      this.$axios
        .get("/api/liuyan")
        .then((response) => {
          this.commentdata = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    setpagedetail() {
      this.$refs.pagedetail.$refs.pagedetail.innerText = "留言板";
    },
  },
  mounted() {
    this.getliuyan();
    this.setpagedetail();
  },
};
</script>

<style scoped>
.Header {
  height: 60vh;
  background-image: url("https://blog.likepoems.com/images/10.png");
}
.content-inner {
  animation: main 1s;
}
.layout {
  display: flex;
  margin: 0 auto;
  padding: 2rem 15px;
}
.layout.hide-aside {
  max-width: 1000px;
}
#page {
  align-self: flex-start;
  padding: 50px 40px;
  border-radius: 8px;
  background: #fff;
  width: 100%;
  box-shadow: 0 4px 8px 6px rgba(7, 17, 27, 0.06);
}
#page:hover {
  -webkit-box-shadow: 0 4px 12px 12px rgba(7, 17, 27, 0.15);
  box-shadow: 0 4px 12px 12px rgba(7, 17, 27, 0.15);
}
#article-container {
  word-wrap: break-word;
  overflow-wrap: break-word;
}
#article-container p {
  margin: 0 0 0.8rem;
}
#post-comment .comment-head {
  margin-bottom: 1rem;
}
#post-comment .comment-head .comment-headline {
  display: inline-block;
  vertical-align: middle;
  font-weight: 700;
  font-size: 1.43em;
}
#post-comment .comment-head .comment-headline i,
#post-comment .comment-head .comment-headline span {
  font-size: 20px;
}

#post-comment .comment-wrap {
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
  padding: 10px;
  box-sizing: border-box;
  line-height: 1.75;
}
.vcards {
  width: 100%;
  box-sizing: border-box;
  line-height: 1.75;
}
.vcards .vcard {
  padding-top: 1.25em;
  position: relative;
  display: block;
}
.vcards .vcard .vhead {
  line-height: 1.5;
  margin-top: 0;
}
.vcards .vcard .vimg {
  width: 2.5em;
  height: 2.5em;
  float: left;
  border-radius: 50%;
  margin-right: 0.7525em;
  border: 1px solid #f5f5f5;
  padding: 0.125em;
  max-width: 100%;
  transition: all 0.3s;
}
.vcards .vcard .vimg:hover {
  transform: rotate(360deg);
}
.vcards .vcard .vh {
  overflow: hidden;
  padding-bottom: 0.5em;
  border-bottom: 1px dashed #f5f5f5;
}
.vcards .vcard .vhead .vnick {
  position: relative;
  font-size: 0.875em;
  font-weight: 500;
  margin-right: 0.4em;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
}
.vvisitor {
  font-size: 12px;
  display: inline-block;
  line-height: 20px;
  border-radius: 2px;
  color: #fff;
  padding: 0 5px;
  position: inherit;
  background: #828282;
}
.vsys {
  display: inline-block;
  padding: 0.2em 1em;
  font-size: 0.75em;
  border-radius: 0.2em;
  margin-right: 0.3em;
  color: #b3b3b3;
  font-family: "Font Awesome 5 Brands";
}
.vsys i {
  margin-right: 0.2em;
}
.vtime {
  position: relative;
  font-size: 0.75em;
  margin-right: 0.875em;
  color: #b3b3b3;
}
.vcontent {
  word-wrap: break-word;
  word-break: break-all;
  font-size: 0.875em;
  line-height: 2;
  position: relative;
  margin-bottom: 0.75em;
  padding-top: 0.625em;
  color: #555;
}
#page /deep/.el-textarea__inner {
  line-height: 40px;
  font-family: Arial;
}
</style>